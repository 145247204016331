<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="60">
        <div slot="header" class="clearfix">
          <span>配置基础信息</span>
        </div>
        <YkcDetailItemPlus label="模板名称">
          {{ dealData(detail.templateName) }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <div style="margin: 10px 0">
        <ykc-table
          title="价格明细"
          :data="detail.priceList || []"
          :columns="priceListColumns"></ykc-table>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { chargeStrategy } from '@/service/apis';

  export default {
    name: 'chargingPriceTemplateDetail',
    data() {
      return {
        showDetail: false,
        fleetTypeNew: [],
        detail: {},
        priceEditRecord: [],
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        priceListColumns: [
          {
            label: '时段',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { priceStartPeriod, priceEndPeriod, defaultFlag } = row;
                if (String(defaultFlag) === '1') {
                  return '其余时段';
                }
                const parse = id => {
                  id = Number(id);
                  const index = id - 1;
                  const hour = Math.floor(index / 2);
                  const minute = (index % 2) * 30;
                  const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
                  return name;
                };
                return `${parse(priceStartPeriod)}~${parse(priceEndPeriod)}`;
              },
            },
          },
          {
            label: '电费',
            scopedSlots: {
              default: ({ row: { powerRate } }) => `${powerRate}元/度`,
            },
          },
          {
            label: '服务费',
            scopedSlots: {
              default: ({ row: { serviceRate } }) => `${serviceRate}元/度`,
            },
          },
          {
            label: '总计单价(元/度)',
            scopedSlots: {
              default: ({ row: { allRate } }) => `${allRate}元/度`,
            },
          },
        ],
        priceListHeaderButtons: [],
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
        const { templateId } = this.$route.query;
        chargeStrategy.chargePriceTemplateDetail({ templateId }).then(res => {
          this.detail = res;
        });
      },
      dealData(data, append) {
        const label = append ? `${data + append}` : data;
        return label || '——';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    display: flex;
    flex-flow: column;
    .box-card {
      .title {
        color: inherit;
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  table {
    width: 100%;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    th {
      height: 44px;
    }
    td {
      height: 40px;
    }
    th,
    td {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }
</style>
